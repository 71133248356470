import React, { useEffect, useState } from "react";
import {
  Table,
  Card,
  Statistic,
  Divider,
  Dropdown,
  Button,
  Modal,
  Input,
  Typography,
  message,
  Radio,
  Checkbox,
} from "antd";
import {
  PlusCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Trash2, Edit } from "lucide-react";

import subscriptionsApi from "../api/subscriptions";

function Susbscriptions() {
  const [subscriptions, setSubscriptions] = useState([]);
  const [targetSubscription, setTargetSubscription] = useState({
    dataType: "COOKIES",
    unauthorizedPages: [],
  });
  const [modalVisibility, setModalVisibility] = useState(false);
  const [modalOperation, setModalOperation] = useState(null);
  const [loading, setLoading] = useState(false);

  const [unauthorizedPagesOption, setUnauthorizedPagesOption] = useState(false);

  const [modal, contextHolder] = Modal.useModal();

  const dataTypes = {
    COOKIES: "Cookies",
    LOCAL_STORAGE: "Local Storage",
    FIREBASE: "Firebase",
  };

  const handleEdit = (item) => async () => {
    setModalOperation("edit");
    setTargetSubscription({
      ...item,
      data: JSON.stringify(item.data, null, 2),
    });
    setModalVisibility(true);
  };
  const handleDelete = (item) => async () => {
    const confirm = await modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined style={{ color: "red" }} />,
      content: "Are you sure you want to delete this subscription ?",
      okText: "Delete",
      cancelText: "Cancel",
    });
    if (!confirm) return null;
    const id = item._id;
    const response = await subscriptionsApi.remove(item._id);
    if (!response.success)
      return message.error(`unable to [delete] ${item.name}`);
    let newData = subscriptions.filter((u) => u._id != id);
    setSubscriptions([...newData]);
    message.success(`${item.name} has been successfully [deleted]`);
  };

  const handleTargetChange = (name) => (event) => {
    setTargetSubscription({
      ...targetSubscription,
      [name]: event.target.value,
    });
  };

  const handleUnauthorizedPages = (event) => {
    let pages = event.target.value;

    pages = pages.split("\n");
    pages = pages.map((page) => {
      page.trim();
      return page;
    });

    setTargetSubscription({ ...targetSubscription, unauthorizedPages: pages });
  };

  const handleSave = async () => {
    const payload = targetSubscription;

    payload["unauthorizedPages"] = payload["unauthorizedPages"].filter(
      (page) => page && page != ""
    );

    if (
      !payload["name"] ||
      !payload["website"] ||
      !payload["data"] ||
      (unauthorizedPagesOption &&
        targetSubscription.unauthorizedPages.length == 0)
    )
      return message.error("all fields are required");

    if (
      payload["reviveTime"] &&
      (payload["reviveTime"] > 59 || payload["reviveTime"] < 5)
    )
      return message.error("revive time has to be 5 <= x =< 59");

    let name = payload["name"];
    name = name[0].toUpperCase() + name.slice(1, name.length);
    payload["name"] = name;

    let parsedData;

    try {
      parsedData = JSON.parse(payload.data);
    } catch (error) {
      return message.error("invalid json data");
    }

    if (modalOperation == "edit") {
      const { _id, ...updateDate } = payload;
      const response = await subscriptionsApi.update(_id, {
        ...updateDate,
        data: parsedData,
      });
      if (!response.acknowledged)
        return message.error(
          `couldn't updated the subscription, Error: ${response.error}`
        );

      const newSubscriptions = [...subscriptions];
      const indx = newSubscriptions.findIndex(
        (subscription) => subscription._id == payload._id
      );

      newSubscriptions[indx] = {
        ...newSubscriptions[indx],
        ...updateDate,
        data: parsedData,
      };
      setSubscriptions(newSubscriptions);

      message.success("successfully updated");
    } else {
      const response = await subscriptionsApi.add({
        ...payload,
        data: parsedData,
      });

      if (!response.success)
        return message.error("failed to complete this operation");

      setSubscriptions([...subscriptions, response.subscription]);
    }

    setModalVisibility(false);
    setModalOperation(null);
    setTargetSubscription({ dataType: "COOKIES", unauthorizedPages: [] });
  };

  const handleAddNew = () => {
    setModalOperation("new");
    setModalVisibility(true);
  };

  const handleModalClose = () => {
    setModalOperation(null);
    setModalVisibility(false);
    setTargetSubscription({ dataType: "COOKIES", unauthorizedPages: [] });
    setUnauthorizedPagesOption(false);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: 150,
      fixed: "left",
    },
    {
      title: "Website",
      dataIndex: "website",
      width: 200,
    },
    {
      title: "Action",
      width: 150,
      render: (item) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            style={{ padding: 1, marginRight: 5 }}
            type="link"
            danger
            onClick={handleDelete(item)}
          >
            <Trash2 />
          </Button>
          <Button
            style={{ padding: 1, marginRight: 5 }}
            type="link"
            onClick={handleEdit(item)}
          >
            <Edit />
          </Button>
        </div>
      ),
    },
  ];

  const loadSubscriptions = async () => {
    const response = await subscriptionsApi.get();
    if (!response.success) return message.error(response.error);
    let newSubscriptions = response.subscription;
    newSubscriptions = newSubscriptions.map((subscription) => {
      subscription["key"] = subscription["_id"];
      return subscription;
    });
    console.log("newSubscriptions: ", newSubscriptions);
    setSubscriptions(newSubscriptions);
  };

  useEffect(() => {
    setLoading(true);
    loadSubscriptions();
    setLoading(false);
  }, []);

  useEffect(() => {
    if (targetSubscription.unauthorizedPages.length > 0)
      setUnauthorizedPagesOption(true);
  }, [targetSubscription]);

  return (
    <>
      <div className="page subscriptions_page">
        <div className="users_statistic">
          <Card bordered={false}>
            <Statistic
              title="Total Subscriptions"
              value={subscriptions.length}
              valueStyle={{ color: "#000000aa" }}
            />
          </Card>
        </div>
        <Divider />
        <div style={{ width: "100%" }}>
          <Button
            type="primary"
            danger
            style={{ marginBottom: 10, marginRight: 10, float: "right" }}
            onClick={handleAddNew}
          >
            <PlusCircleOutlined />
            Add
          </Button>
          <Table
            columns={columns}
            dataSource={subscriptions}
            scroll={{ x: 300, y: window.innerHeight - (276.825 + 10 + 72) }}
            pagination={false}
            size="default"
            loading={loading}
          />
        </div>
      </div>
      <Modal
        open={modalVisibility}
        onCancel={handleModalClose}
        onOk={handleSave}
        okText={modalOperation == "edit" ? "Save" : "Add"}
      >
        <div className="subscriptions_edit_form">
          <Typography.Text>Name</Typography.Text>
          <Input
            placeholder="ex: Twitter"
            value={targetSubscription?.name}
            onChange={handleTargetChange("name")}
          />
          <Typography.Text>Website</Typography.Text>
          <Input
            addonBefore="https://"
            placeholder="twitter.com"
            value={targetSubscription?.website}
            onChange={handleTargetChange("website")}
          />

          <Typography.Text>Revive Time</Typography.Text>
          <Input
            defaultValue={5}
            min={5}
            max={59}
            type="number"
            addonAfter="Minutes"
            value={targetSubscription?.reviveTime}
            onChange={handleTargetChange("reviveTime")}
          />

          <Typography.Text>
            {dataTypes[targetSubscription?.dataType]}
          </Typography.Text>
          <Input.TextArea
            value={targetSubscription?.data}
            onChange={handleTargetChange("data")}
            placeholder='JSON:	
          [{"key":"value"}]'
            autoSize={{
              minRows: 10,
              maxRows: 5,
            }}
          />
        </div>
        <Radio.Group
          onChange={(e) =>
            setTargetSubscription({
              ...targetSubscription,
              dataType: e.target.value,
            })
          }
          value={targetSubscription.dataType}
        >
          <Radio value={"COOKIES"}>{dataTypes["COOKIES"]}</Radio>
          <Radio value={"LOCAL_STORAGE"}>{dataTypes["LOCAL_STORAGE"]}</Radio>
          <Radio value={"FIREBASE"}>{dataTypes["FIREBASE"]}</Radio>
        </Radio.Group>
        <div style={{ marginTop: 10 }}>
          <Checkbox
            checked={
              unauthorizedPagesOption ||
              targetSubscription.unauthorizedPages.length > 0
            }
            onChange={(event) => {
              const value = event.target.checked;
              if (!value)
                setTargetSubscription({
                  ...targetSubscription,
                  unauthorizedPages: [],
                });
              setUnauthorizedPagesOption(value);
            }}
          >
            Add unauthorized pages
          </Checkbox>
          {unauthorizedPagesOption && (
            <Input.TextArea
              rows={10}
              placeholder={`https://www.example.com/path/unauthorized/page1\nhttps://www.example.com/path/unauthorized/page2\n...etc`}
              value={targetSubscription.unauthorizedPages.join("\n")}
              onChange={handleUnauthorizedPages}
            />
          )}
        </div>
      </Modal>
      {contextHolder}
    </>
  );
}

export default Susbscriptions;
